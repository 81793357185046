import React from 'react';
import MainHeroButton from '@app/components/client/heroButtons/mainHeroButton/MainHeroButton';
import { IPageHeroButton } from '@app/models/pages/pages';
import { IHeroButtonProps } from '@app/models/heroButtons/mainHeroButton';

interface IProps extends IHeroButtonProps {
    /**
     * Детали для кнопок
     */
    buttons?: IPageHeroButton[];

    /**
     * Находится ли контейнер в мобильном варианте, чтобы убрать некоторые кнопки
     */
    isMobileContainer?: boolean;
}

/**
 *
 */
export default function MainHeroButtonContainer({
    buttons,
    isMobileContainer,
    ...props
}: IProps) {
    return buttons?.map(item => {
        if (item.hide_mobile && isMobileContainer) {
            return;
        }
        return (
            <MainHeroButton
                {...item}
                {...props}
                key={item.title}
            />
        );
    });
};
