import React from 'react';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import {
    IPageHeroButton,
    PageHeroButtonAction,
} from '@app/models/pages/pages';
import BecomeInvestorForm from '@app/components/client/investors/BecomeInvestorForm/BecomeInvestorForm';
import BecomeBorrowerForm from '@app/components/client/borrowers/BecomeBorrowerForm/BecomeBorrowerForm';
import GoToSectionButton from '@app/components/client/common/GoToSectionButton/GoToSectionButton';
import BecomePartnerForm from '@app/components/client/partners/BecomePartnerForm/BecomePartnerForm';
import { IHeroButtonProps } from '@app/models/heroButtons/mainHeroButton';

interface IProps extends IPageHeroButton, IHeroButtonProps {
    /**
     * Ссылка при типе PageHeroButtonAction.Link
     */
    href?: string;
}

/**
 * Компонент отображения кнопок в Hero
 */
export default function MainHeroButton({
    page,
    action,
    title,
    formType,
    width,
    block,
    size,
    section,
    className,
    href,
}: IProps) {

    if (page === 'investor' && action === PageHeroButtonAction.Register) {
        return (
            <BecomeInvestorForm
                type={formType}
                buttonText={title}
            />
        );
    }

    if (page === 'borrower' && action === PageHeroButtonAction.Register) {
        return (
            <BecomeBorrowerForm
                buttonText={title}
                width={width}
                block={block}
                size={size}
            />
        );
    }
    if (action === PageHeroButtonAction.ToSection) {
        return (
            <GoToSectionButton
                text={title}
                outline
                size={size}
                block={block}
                targetSelector={`[data-anchor='${section}']`}
            />
        );
    }

    if (page === 'partneram' && action === PageHeroButtonAction.Register) {
        return (
            <BecomePartnerForm
                buttonText={title}
                block={block}
                size={size}
                className={className}
            />
        );
    }

    if (action === PageHeroButtonAction.Link) {
        return (
            <Button
                text={title}
                block={block}
                size={size}
                href={href}
                className={className}
            />
        );
    }

    return null;
};
