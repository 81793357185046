// YOAST SEO Model
export interface IPageSeoApiModel {
    title?: string;
    description?: string;
    robots?: {
        index?: string;
        follow?: string;
        'max-image-preview'?: string;
        'max-video-preview'?: string;
    };
    canonical?: string;
    og_locale?: string;
    og_type?: string;
    og_title?: string;
    og_description?: string;
    og_url?: string;
    og_site_name?: string;
    article_modified_time?: string;
    og_image?: any[];
    twitter_card?: string;
    twitter_title?: string;
    twitter_description?: string;
    twitter_image?: string;
    // schema.org model
    schema?: any;
}

export interface IPageSeoWrapperApiModel {
    html: string;
    json: IPageSeoApiModel;
}

/**
 * Типы действия для кнопки в Hero
 */
export enum PageHeroButtonAction {
    /**
     * Регистрация
     */
    Register = 'register',

    /**
     * Переход по ссылке, тогда есть поле **url**
     */
    Link = 'link',

    /**
     * Переход к секции, тогда есть поле **section**
     */
    ToSection = 'to-section'
}

/**
 * Детали для кнопки в Hero
 */
export interface IPageHeroButton {
    /**
     * Название кнопки
     */
    title?: string;

    /**
     * Действие кнопки
     */
    action: PageHeroButtonAction;

    /**
     * Скрыта ли кнопка на мобилке
     */
    hide_mobile?: boolean;

    /**
     * Ссылка, при action === PageHeroButtonAction.Link
     */
    url?: string;

    /**
     * Секция, при action === PageHeroButtonAction.Section
     */
    section?: string;
}

export interface IPageHeroApiModel {
    title?: string;
    title_highlight?: string;
    description?: string;

    /**
     * Список кнопок в Hero
     */
    buttons?: IPageHeroButton[];
}

export interface IPageFieldsApiModel {
    seo_text_content?: string;
    hero?: IPageHeroApiModel;
    // TODO Use typings
    blocks?: any[];

    /**
     * Текст на лид кнопке
     */
    lead_button_title?: string;
}

export interface IAuthPageFieldsApiModel {
    marketing?: {
        enabled: boolean;
        content?: string;
        badges?: Array<{
            title: string;
        }>;
    };
}

export interface IBasePageApiModel {
    id: string;
    slug?: string;
    url: string;
    title: string;
    description: string;
    preview_url?: string;
    /**
     * Текст alt тега изображения
     */
    preview_alt?: string;
    seo?: IPageSeoWrapperApiModel;
    date?: string;
    content?: string;
    fields: IPageFieldsApiModel;
}

export interface IPagesMenuApiModel {
    /**
     * Идентификатор
     */
    id: number;
    /**
     * Заголовок
     */
    title: string;
    /**
     * Ссылка
     */
    url: string;
    /**
     * Внешняя ли ссылка
     */
    external: boolean;
    /**
     * Подменю
     */
    submenu_items?: Array<{
        /**
         * Идентификатор
         */
        id: number;
        /**
         * Заголовок
         */
        title: string;
        /**
         * Ссылка
         */
        url: string;
        /**
         * Внешняя ли ссылка
         */
        external?: boolean;
    }>;
}

export interface ISiteSeoApiModel {
    index?: IPageSeoWrapperApiModel;
    not_found?: IPageSeoWrapperApiModel;
}
